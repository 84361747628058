<template>
    <div class="page">
        <div class="align">
            <div class="title">
                幸运码: {{mysteryBoxCode}}<br />
            </div>
            <div class="msg">
                有效兑换时间截止到:  {{expireTime}}<br /><br />

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Confirm',
        data() {
            return {
                mysteryBoxCode: '',
                expireTime: '',
            }
        },
        mounted() {
            this.mysteryBoxCode = this.$route.query.mysteryBoxCode
            this.expireTime = this.$route.query.expireTime
        },
        methods: {
            close() {
                WeixinJSBridge.invoke('closeWindow')
            },
        },
    }
</script>

<style
    scoped
    lang="scss"
>
    @import "src/design/common";

    .page {
        height: 100%;
        padding: 15px;
        background: #EBE8E1;
        text-align: center;
        position: relative;

        .align {
            width: 100%;
            position: absolute;
            left: 50%;
            top: 40%;
            transform: translate(-50%, -40%);
        }

        .title {
            color: #4b4c50;
            font-size: 20px;
            font-weight: 500;
            padding-bottom: 30px;
        }

        .msg {
            color: #7d7d7d;
            font-weight: 400;
            font-size: 12px;
            padding-bottom: 60px;
        }

        .close-btn {
            background-color: white;
            height: 50px;
            border-radius: 8px;
            font-size: 16px;
            font-family: $font-family;
            font-weight: 400;
            color: $primary-color;
            margin: 0 auto;
            width: 80%;
            border: 2px solid $primary-color;
        }
    }
</style>
